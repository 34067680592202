export function secondsToHMS(seconds: number) {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds - h * 3600) / 60)
  const s = seconds - h * 3600 - m * 60

  const formattedHours = h.toString().padStart(2, '0')
  const formattedMinutes = m.toString().padStart(2, '0')
  const formattedSeconds = s.toString().padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}

export function secondsToHM(seconds: number) {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds - h * 3600) / 60)

  const formattedHours = h.toString().padStart(2, '0')
  const formattedMinutes = m.toString().padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}`
}

export function hmsToSeconds(hms: string) {
  const [h, m, s] = hms.split(':')
  // TODO: add guard
  return parseInt(h!) * 3600 + parseInt(m!) * 60 + parseInt(s!)
}
