/* eslint-disable effector/no-patronum-debug */
'use client'

import { getClientScope } from '@effector/next'
import Cookies from 'js-cookie'
import { debug } from 'patronum/debug'
import { useEffect, useRef } from 'react'
import { REFRESH_TOKEN_STORAGE_KEY } from '~/constants'
import { flags } from '~/flags'
import { parseJwt } from '~/shared/lib/http/jwt'

function setToken(refreshToken: string) {
  Cookies.set(REFRESH_TOKEN_STORAGE_KEY, refreshToken, {
    path: '/',
    expires: new Date((parseJwt(refreshToken)?.exp ?? 0) * 1000),
  })
}

if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development') {
  window.__DEBUG = { setToken }
}

export function DebugTools() {
  const debugSubscriptionRef = useRef<(() => void) | null>(null)

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && flags.debug.effectorScope) {
      debugSubscriptionRef.current = debug.registerScope(getClientScope()!, {
        name: 'clientScope',
      })
    }
    return () => {
      if (debugSubscriptionRef.current) {
        debugSubscriptionRef.current()
      }
    }
  }, [])

  return null
}
