import { createFactory } from '@withease/factories'
import { format, subDays, subMonths, subWeeks, subYears } from 'date-fns'
import type { StoreValue } from 'effector'
import { combine, createEvent, createStore, sample } from 'effector'
import type {
  DateRange as PickerDateRange,
  SelectRangeEventHandler,
} from 'react-day-picker'
import { keys, map, pipe } from 'remeda'
import { $channelServiceRegisteredAtDate } from '~/app/dashboard/c/[channel_id]/channel.model'
import { hmsToSeconds, secondsToHMS } from '../../format-seconds'

function formatDateRange(range?: PickerDateRange): DateRange {
  return {
    start_date: range?.from ? format(range.from, 'yyyy-MM-dd') : null,
    end_date: range?.to ? format(range.to, 'yyyy-MM-dd') : null,
  }
}

type DateRange = {
  start_date?: string | null
  end_date?: string | null
}

type TimeRange = {
  start_time: string
  end_time: string
}

const DATE_RANGE_PRESET_ENUM = {
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year',
} as const

const dateRangePresetTitleMap: Record<DateRangePreset, string> = {
  day: 'День',
  week: 'Неделя',
  month: 'Месяц',
  year: 'Год',
}

export type DateRangePreset = keyof typeof DATE_RANGE_PRESET_ENUM

export const presetsOptions = pipe(
  DATE_RANGE_PRESET_ENUM,
  presetEnum => keys(presetEnum),
  map(presetKey => ({
    value: presetKey,
    label: dateRangePresetTitleMap[presetKey],
  })),
)

type PayloadPreset = {
  preset: DateRangePreset
}

type PayloadValue = {
  value: PickerDateRange
}

type DateRangePayload = PayloadPreset | PayloadValue

type Config = {
  initialStartDate?: Date | null
  initialEndDate?: Date | null
  preset?: DateRangePreset | null
}

function createDateTimeRangeModelImpl(config: Config = {}) {
  const {
    initialStartDate = subDays(new Date(), 7),
    initialEndDate = subDays(new Date(), 0),
    preset = 'week',
  } = config

  const dateRangeChanged = createEvent<DateRangePayload>()

  const $dateRange = createStore<DateRange>({
    start_date: initialStartDate
      ? format(initialStartDate, 'yyyy-MM-dd')
      : null,
    end_date: initialEndDate ? format(initialEndDate, 'yyyy-MM-dd') : null,
  })

  const timeRangeChanged = createEvent<TimeRange>()

  const $timeRange = createStore<TimeRange>({
    start_time: '00:00:00',
    end_time: '23:59:59',
  })

  const $dateRangePreset = createStore<DateRangePreset | null>(preset, {
    skipVoid: false,
  })

  const $isDay = $dateRange.map(
    ({ start_date, end_date }) =>
      !!(start_date && end_date && start_date === end_date),
  )

  const $dateTimeRange = combine(
    [$dateRange, $timeRange],
    ([{ start_date, end_date }, { start_time, end_time }]) => ({
      start_datetime: start_date ? `${start_date} ${start_time}` : null,
      end_datetime: end_date ? `${end_date} ${end_time}` : null,
    }),
  )

  sample({
    clock: dateRangeChanged,
    source: $dateRange,
    filter: (_, eventValue: DateRangePayload): eventValue is PayloadValue =>
      'value' in eventValue,
    fn: (currentDateRange, eventValue): StoreValue<typeof $dateRange> => {
      const range = (eventValue as PayloadValue).value
      return range
        ? formatDateRange(range)
        : {
            start_date: currentDateRange.start_date,
            end_date: currentDateRange.start_date,
          }
    },
    target: $dateRange,
  })

  sample({
    clock: dateRangeChanged,
    source: { channelServiceRegisteredAtDate: $channelServiceRegisteredAtDate },
    filter: (_, payload) => 'preset' in payload,
    fn: ({ channelServiceRegisteredAtDate }, payload) => {
      const FORMAT = 'yyyy-MM-dd'
      const { preset } = payload as PayloadPreset

      const endDate = format(new Date(), FORMAT)

      let startDate: Date

      switch (preset) {
        case 'day':
          startDate = new Date()
          break
        case 'week':
          startDate = subWeeks(new Date(), 1)
          break
        case 'month':
          startDate = subMonths(new Date(), 1)
          break
        case 'year':
          startDate = subYears(new Date(), 1)
          break
      }

      if (channelServiceRegisteredAtDate.getTime() > startDate.getTime()) {
        startDate = channelServiceRegisteredAtDate
      }

      return {
        start_date: format(startDate, FORMAT),
        end_date: endDate,
      }
    },
    target: $dateRange,
  })

  sample({
    clock: timeRangeChanged,
    target: $timeRange,
  })

  sample({
    clock: dateRangeChanged,
    filter: (payload: DateRangePayload): payload is PayloadPreset =>
      'preset' in payload,
    fn: ({ preset }) => preset,
    target: $dateRangePreset,
  })

  sample({
    clock: dateRangeChanged,
    filter: (payload: DateRangePayload): payload is PayloadValue =>
      'value' in payload,
    fn: () => null,
    target: $dateRangePreset,
  })

  sample({
    clock: $isDay,
    filter: is => !is,
    target: $timeRange.reinit,
  })

  const reset = createEvent()

  sample({
    clock: reset,
    target: [
      $dateRange.reinit,
      $timeRange.reinit,
      $dateRangePreset.reinit,
    ] as const,
  })

  const dateRangeSelected = createEvent<Parameters<SelectRangeEventHandler>>()

  sample({
    clock: dateRangeSelected,
    source: { dateRange: $dateRange },
    filter: (_, [range]) => !!range,
    fn: ({ dateRange }, [range, selectedDay]) => {
      const from = dateRange.start_date
        ? new Date(dateRange.start_date)
        : undefined
      const to = dateRange.end_date ? new Date(dateRange.end_date) : undefined
      const nextValue =
        from && to
          ? { value: { from: selectedDay, to: undefined } }
          : {
              value: range!,
            }
      return nextValue
    },
    target: dateRangeChanged,
  })

  const $timeRangeTuple = combine([$timeRange], ([timeRange]) => {
    return [
      hmsToSeconds(timeRange.start_time),
      hmsToSeconds(timeRange.end_time),
    ]
  })

  const changeTimeFromTuple = createEvent<[number, number]>()

  sample({
    clock: changeTimeFromTuple,
    fn: ([start, end]) => ({
      start_time: secondsToHMS(start),
      end_time: secondsToHMS(end),
    }),
    target: timeRangeChanged,
  })

  return {
    $dateRange,
    $timeRange,
    $dateTimeRange,
    reset,
    dateRangeChanged,
    timeRangeChanged,
    $dateRangePreset,
    $isDay,
    dateRangeSelected,
    $timeRangeTuple,
    changeTimeFromTuple,
  }
}

export const createDateTimeRangeModel = createFactory(
  createDateTimeRangeModelImpl,
)
