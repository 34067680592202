import { ContentTabGate } from '@channel/(new_layout)/content/model'
import '@channel/(old)/(base)/model'
import { BaseMetricsTabGate } from '@channel/(old)/(base)/model'
import { SalesTabGate } from '@channel/(old)/sales/model'
import { createSaleReportMutation } from '@channel/(old)/sales/v2/create_report/model'
import { reportInviteLinksFilterModel } from '@channel/(old)/sales/v2/report_filters/model'
import { $selectedSalesReport } from '@channel/(old)/sales/v2/reports_list/model'
import { updateReportNameMutation } from '@channel/(old)/sales/v2/sales_analytics/rename_report/model'
import { salesReportBudgetChanged } from '@channel/(old)/sales/v2/sales_analytics/update_report_budget/model'
import { salesReportSourceBudgetChanged } from '@channel/(old)/sales/v2/sales_analytics/update_report_sourced_invite_link_budget/model'
import {
  trafficReleaseDisclosureAtom,
  trafficReleaseNextButtonClicked,
} from '@channel/(old)/traffic/features/release_modal/model'
import { TrafficsTabGate } from '@channel/(old)/traffic/model'
import { downloadReportClicked } from '@channel/(old)/traffic/unloading-reports/model'
import { adsCampaignsChartDataViewTypeAtom } from '@channel/(old)/traffic/v2/ads_campaigns/data_view_type_model'
import type { UsernameChartDataKeys } from '@channel/(old)/traffic/v2/presence_username/chart.model'
import { usernamesChartActiveKeyAtom } from '@channel/(old)/traffic/v2/presence_username/chart.model'
import { unsubsCohortChartPeriodAtom } from '@channel/(old)/traffic/v2/unsubs_cohorts/chart.model'
import { unsubsPercentsChartPeriodAtom } from '@channel/(old)/traffic/v2/unsubs_percents/period.model'
import { ChannelDashboardGate } from '@channel/channel.model'
import { $$datetimeRangeFilterModel } from '@channel/widgets/analytics_filters/datetime_range_filter/model'
import { sharedInviteLinksFilterModel } from '@channel/widgets/analytics_filters/invite_links_filter/model'
import { createInviteLinkMutation } from '@channel/widgets/invite_links_dialog/create_link/model'
import { sample } from 'effector'
import { createAction } from 'effector-action'
import { debounce } from 'patronum'
import {
  $selectedChannel,
  inviteParticipantMutation,
  profileChannelsAddChannelClicked,
  profileChannelsAdminsSectionClicked,
} from '~/app/dashboard/profile/_page/model'
import { ProfilePageGate } from '~/app/dashboard/profile/page_model'
import { smitLinkBannerButtonClicked } from '~/features/banner/model'
import { sidebarSelectedFilter } from '~/features/channel/filters/model'
import { channelsTitleSorter } from '~/features/channel/sorting/model'
import { toggleFavoriteChannelMutation } from '~/features/channel/toggle-favorite/model'
import {
  releaseDisclosureAtom,
  releaseNextButtonClicked,
} from '~/features/release_modal/model'
import { currentUserAccountQuery } from '~/user/model'
import { signoutButtonClicked } from '~/widgets/account-dropdown/model'
import { analyticsEvent } from './events'
import {
  sendAnalyticsEvents,
  setAnalyticsChannelId,
  setAnalyticsUserId,
} from './model'

// Авторизовался автоматически
createAction(currentUserAccountQuery.finished.success, {
  target: { setAnalyticsUserId, sendAnalyticsEvents },
  fn: (target, { result: { id } }) => {
    target.setAnalyticsUserId(id)
    target.sendAnalyticsEvents([
      analyticsEvent.auth_signin_auto({ user_id: id }),
    ])
  },
})

// Вышел из профиля через кнопку выйти
sample({
  clock: signoutButtonClicked,
  fn: () => [analyticsEvent.auth_signout_via_signout_button()],
  target: sendAnalyticsEvents,
})

// Перешел в раздел «мой профиль»
sample({
  clock: debounce(ProfilePageGate.open, 0),
  fn: () => [analyticsEvent.profile_section_opened()],
  target: sendAnalyticsEvents,
})

// Подключил канал
sample({
  clock: profileChannelsAddChannelClicked,
  fn: () => [analyticsEvent.channel_connected()],
  target: sendAnalyticsEvents,
})

// Перешел в канал
createAction(debounce(ChannelDashboardGate.open, 0), {
  target: { setAnalyticsChannelId, sendAnalyticsEvents },
  fn: (target, { params: { channel_id } }) => {
    target.setAnalyticsChannelId(+channel_id)
    target.sendAnalyticsEvents([analyticsEvent.channel_opened()])
  },
})

// Перешел в «управление администраторами»
sample({
  clock: profileChannelsAdminsSectionClicked,
  fn: () => [analyticsEvent.admin_management_opened()],
  target: sendAnalyticsEvents,
})

// Выбрал канал в «управление администраторами»
createAction($selectedChannel, {
  target: { sendAnalyticsEvents },
  fn: ({ sendAnalyticsEvents }, channel) => {
    if (channel) {
      sendAnalyticsEvents([
        analyticsEvent.admin_channel_selected({ channel_name: channel.title }),
      ])
    }
  },
})

// Добавил участника
// Добавил администратора в канал
createAction(inviteParticipantMutation.finished.success, {
  target: { sendAnalyticsEvents },
  source: { $selectedChannel },
  fn: ({ sendAnalyticsEvents }, { selectedChannel }, { params: { body } }) => {
    if (selectedChannel) {
      const isAdminAdded = body.some(({ role }) => role === 'admin')

      const events = [analyticsEvent.participant_added()]

      if (isAdminAdded) {
        events.push(
          analyticsEvent.admin_added_to_channel({
            channel_name: selectedChannel.title,
          }),
        )
      }

      sendAnalyticsEvents(events)
    }
  },
})

// Кликнул по кнопке «оставить заявку в смит линк»
sample({
  clock: smitLinkBannerButtonClicked,
  fn: () => [analyticsEvent.submit_request_click_smit_link()],
  target: sendAnalyticsEvents,
})

// Отсортировал каналы
createAction(channelsTitleSorter.sortingAscendingOrderChanged, {
  target: { sendAnalyticsEvents },
  fn: ({ sendAnalyticsEvents }) => {
    sendAnalyticsEvents([analyticsEvent.channels_sorted()])
  },
})

// Добавил канал в избранное
createAction(toggleFavoriteChannelMutation.finished.success, {
  target: { sendAnalyticsEvents },
  fn: (
    { sendAnalyticsEvents },
    {
      result: {
        channel: { title },
      },
    },
  ) => {
    sendAnalyticsEvents([
      analyticsEvent.channel_added_to_favorites({ channel_name: title }),
    ])
  },
})

// Создал пригласительную ссылку
sample({
  clock: createInviteLinkMutation.finished.success,
  fn: () => [analyticsEvent.invite_link_created()],
  target: sendAnalyticsEvents,
})

// Выбрал поиск по роли
sample({
  clock: sidebarSelectedFilter.$value,
  filter: value => value === 'role',
  fn: () => [analyticsEvent.role_search_selected()],
  target: sendAnalyticsEvents,
})

// Перешел на базовые метрики
createAction(debounce(BaseMetricsTabGate.open, 500), {
  target: { sendAnalyticsEvents },
  fn: ({ sendAnalyticsEvents }) => {
    sendAnalyticsEvents([analyticsEvent.base_metrics_view_opened()])
  },
})

// Настроил период в базовых метриках
createAction($$datetimeRangeFilterModel.$dateTimeRange, {
  source: { isActive: BaseMetricsTabGate.status },
  target: { sendAnalyticsEvents: sendAnalyticsEvents },
  fn: (target, { isActive }, period) => {
    if (isActive && period.end_datetime && period.start_datetime) {
      target.sendAnalyticsEvents([analyticsEvent.base_metrics_period_set()])
    }
  },
})

// Перешел на трафик
sample({
  clock: debounce(TrafficsTabGate.open, 0),
  source: { isActive: TrafficsTabGate.status },
  filter: ({ isActive }) => isActive,
  fn: () => [analyticsEvent.traffic_view_opened()],
  target: sendAnalyticsEvents,
})

// Настроил период в трафике
createAction($$datetimeRangeFilterModel.$dateTimeRange, {
  source: { isActive: TrafficsTabGate.status },
  target: { sendAnalyticsEvents: sendAnalyticsEvents },
  fn: (target, { isActive }, period) => {
    if (isActive && period.end_datetime && period.start_datetime) {
      target.sendAnalyticsEvents([analyticsEvent.traffic_period_set()])
    }
  },
})

// Выбрал ссылки (фильтрация)
createAction(sharedInviteLinksFilterModel.$inviteLinksHashList, {
  target: { sendAnalyticsEvents },
  fn: target => {
    target.sendAnalyticsEvents([analyticsEvent.traffic_links_filtered()])
  },
})

// Выбрал фильтр в «Список рекламных кампаний»
createAction(adsCampaignsChartDataViewTypeAtom.set, {
  target: { sendAnalyticsEvents: sendAnalyticsEvents },
  fn: (target, value) => {
    if (value !== 'Link') {
      target.sendAnalyticsEvents([
        analyticsEvent.traffic_campaigns_filtered({ value }),
      ])
    }
  },
})

// Наличие никнеймов
const usernamesChartKeysMap = {
  subs: 'subscription',
  unsubs: 'unsubscription',
  username: 'with_nickname',
  no_username: 'without_nickname',
} as Record<
  UsernameChartDataKeys,
  Parameters<typeof analyticsEvent.traffic_nicknames_filtered>['0']['value']
>

sample({
  clock: usernamesChartActiveKeyAtom.set,
  fn: dataKey => [
    analyticsEvent.traffic_nicknames_filtered({
      value: usernamesChartKeysMap[dataKey],
    }),
  ],
  target: sendAnalyticsEvents,
})

// Процент отписок новых подписчиков, когорты отписок
sample({
  clock: [
    unsubsCohortChartPeriodAtom.$value,
    unsubsPercentsChartPeriodAtom.$value,
  ],
  fn: value => [analyticsEvent.traffic_unsub_percentage_set({ value })],
  target: sendAnalyticsEvents,
})

// Сделал выгрузку из Трафика «аудитория»
sample({
  clock: downloadReportClicked,
  fn: () => [analyticsEvent.traffic_audience_exported()],
  target: sendAnalyticsEvents,
})

// Перешел на модуль Контент
sample({
  clock: debounce(ContentTabGate.open, 0),
  filter: ContentTabGate.status,
  fn: () => [analyticsEvent.content_module_opened()],
  target: sendAnalyticsEvents,
})

// Перешел на раздел Продажи
sample({
  clock: debounce(SalesTabGate.open, 0),
  filter: SalesTabGate.status,
  fn: () => [analyticsEvent.sales_view_opened()],
  target: sendAnalyticsEvents,
})

// Создал выгрузку в «Продажи»
sample({
  clock: createSaleReportMutation.finished.success,
  fn: () => [analyticsEvent.sales_export_created()],
  target: sendAnalyticsEvents,
})

// Выбрал выгрузку
sample({
  clock: $selectedSalesReport,
  fn: () => [analyticsEvent.sales_export_selected()],
  target: sendAnalyticsEvents,
})

// Внес сумму рекламной кампании
sample({
  clock: salesReportSourceBudgetChanged,
  fn: () => [analyticsEvent.sales_campaign_budget_set()],
  target: sendAnalyticsEvents,
})

// Внес потрачено на рекламную кампанию
sample({
  clock: salesReportBudgetChanged,
  fn: () => [analyticsEvent.sales_expenses_entered()],
  target: sendAnalyticsEvents,
})

// Переименовал сверку
sample({
  clock: updateReportNameMutation.finished.success,
  fn: () => [analyticsEvent.sales_verification_renamed()],
  target: sendAnalyticsEvents,
})

// Выбрал ссылки
sample({
  clock: reportInviteLinksFilterModel.$inviteLinksHashList,
  fn: () => [analyticsEvent.sales_links_filtered()],
  target: sendAnalyticsEvents,
})

// Перешел по кнопке в поп-апе название
sample({
  clock: releaseNextButtonClicked,
  fn: () => [
    analyticsEvent.popup_button_clicked({
      popup_name: 'new_release',
      button_name: 'next',
    }),
  ],
  target: sendAnalyticsEvents,
})

sample({
  clock: trafficReleaseNextButtonClicked,
  fn: () => [
    analyticsEvent.popup_button_clicked({
      popup_name: 'new_release',
      button_name: 'next',
    }),
  ],
  target: sendAnalyticsEvents,
})

// Закрыл поп-ап название
sample({
  clock: releaseDisclosureAtom.close,
  fn: () => [
    analyticsEvent.popup_closed({
      popup_name: 'new_release',
    }),
  ],
  target: sendAnalyticsEvents,
})

sample({
  clock: trafficReleaseDisclosureAtom.close,
  fn: () => [
    analyticsEvent.popup_closed({
      popup_name: 'new_release',
    }),
  ],
  target: sendAnalyticsEvents,
})
