import { env } from './env.mjs'

const _isDevOnly =
  process.env.NODE_ENV === 'development' || env.NEXT_PUBLIC_PRODUCTION !== 1

export const flags = {
  isPreview:
    process.env.NODE_ENV === 'development' || env.NEXT_PUBLIC_PRODUCTION !== 1,
  future: {},
  debug: {
    effectorScope: false,
  },
} as const
