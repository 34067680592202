import(/* webpackMode: "eager", webpackExports: ["EffectorNext"] */ "/app/node_modules/.pnpm/@effector+next@0.7.0_effector-react@23.2.1_effector@23.2.3_react@19.0.0-rc-66855b96-20241106__ge3l32hsmaj3ze7lioxs3kphpq/node_modules/@effector/next/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.3_next@15.0.3_react-dom@19.0.0-rc-66855b96-20241106_react@19.0.0-rc-_vt4vekp5ev2gpkvwwx34qejls4/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.3_next@15.0.3_react-dom@19.0.0-rc-66855b96-20241106_react@19.0.0-rc-_vt4vekp5ev2gpkvwwx34qejls4/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.3_next@15.0.3_react-dom@19.0.0-rc-66855b96-20241106_react@19.0.0-rc-_vt4vekp5ev2gpkvwwx34qejls4/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/node_modules/.pnpm/next-themes@0.4.3_react-dom@19.0.0-rc-66855b96-20241106_react@19.0.0-rc-66855b96-20241106__re_del6le64ndnr3g3v6f5dcr2qcq/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.3_react-dom@19.0.0-rc-66855b96-20241106_react@19.0.0-rc-66855b96-20241106__react@19.0.0-rc-66855b96-20241106/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.3_react-dom@19.0.0-rc-66855b96-20241106_react@19.0.0-rc-66855b96-20241106__react@19.0.0-rc-66855b96-20241106/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Manrope\",\"arguments\":[{\"subsets\":[\"cyrillic-ext\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"variable\":\"--font-manrope\"}],\"variableName\":\"manrope\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.3_react-dom@19.0.0-rc-66855b96-20241106_react@19.0.0-rc-66855b96-20241106__react@19.0.0-rc-66855b96-20241106/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"cyrillic-ext\"],\"weight\":[\"600\"],\"variable\":\"--font-montserrat\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.3_react-dom@19.0.0-rc-66855b96-20241106_react@19.0.0-rc-66855b96-20241106__react@19.0.0-rc-66855b96-20241106/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Golos_Text\",\"arguments\":[{\"subsets\":[\"cyrillic-ext\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"variable\":\"--font-golos-text\"}],\"variableName\":\"golosTextFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/handsontable@14.6.1/node_modules/handsontable/dist/handsontable.full.min.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/keen-slider@6.8.6/node_modules/keen-slider/keen-slider.min.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-day-picker@8.10.1_date-fns@4.1.0_react@19.0.0-rc-66855b96-20241106/node_modules/react-day-picker/dist/style.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/node_modules/.pnpm/sonner@1.7.0_react-dom@19.0.0-rc-66855b96-20241106_react@19.0.0-rc-66855b96-20241106__react@19.0.0-rc-66855b96-20241106/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["AppGate"] */ "/app/src/app/_layout/application-gate.ts");
;
import(/* webpackMode: "eager", webpackExports: ["ClientRouterGate"] */ "/app/src/app/_layout/client-router-gate.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationEvents"] */ "/app/src/app/_layout/navigation-events.ts");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/app/src/app/_layout/tooltip-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["DebugTools"] */ "/app/src/processes/debug-tools.ts");
;
import(/* webpackMode: "eager", webpackExports: ["YandexMetrika"] */ "/app/src/processes/yandex-metrika/index.tsx");
