import { createEffect, createEvent, sample } from 'effector'
import { createAction } from 'effector-action'
import type { NavigationRouteChangedEventPayload } from '~/entities/navigation/use-navigation'
import { env } from '~/env.mjs'

export const routeChangedAnalyticsEvent =
  createEvent<NavigationRouteChangedEventPayload>()

const routeChangedYMSendFx = createEffect<
  Pick<NavigationRouteChangedEventPayload, 'url'>,
  void
>(({ url }) => {
  if (typeof window !== 'undefined') {
    if (typeof ym === 'function') {
      ym(env.NEXT_PUBLIC_YANDEX_METRIKA_KEY, 'hit', url)
    }
  }
})

sample({
  clock: routeChangedAnalyticsEvent,
  target: createAction({
    target: {
      routeChangedYMSendFx,
    },
    fn: (
      { routeChangedYMSendFx },
      { url }: NavigationRouteChangedEventPayload,
    ) => {
      routeChangedYMSendFx({ url })
    },
  }),
})
