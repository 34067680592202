'use client'

import { useUnit } from 'effector-react'
import { useNavigation } from '~/entities/navigation/use-navigation'
import { routeChangedAnalyticsEvent } from '~/processes/yandex-metrika/model'

export function YandexMetrika() {
  const { routeChanged } = useUnit({
    routeChanged: routeChangedAnalyticsEvent,
  })

  useNavigation({
    on: {
      routeChanged,
    },
  })

  return null
}
