import type { EventPayload } from 'effector'
import { combine, createEvent, sample } from 'effector'
import type { FetchOptions } from '~/api/client'
import { createDisclosureAtomImpl } from '~/shared/lib/factories/create-disclosure'
import { createStateOld } from '~/shared/lib/factories/create-state.old'
import { currentUserChannelsQuery } from '~/user/model'

type Opts = NonNullable<
  NonNullable<FetchOptions<'get', '/channels'>['params']>['query']
>

export const titleFilterState = createStateOld<Opts['title'] | null>({
  initial: null,
})
export const isFavoriteFilterState = createStateOld<Opts['is_favorite'] | null>(
  { initial: null },
)
export const rolesFilterState = createStateOld<Opts['role']>({ initial: [] })

const $channelFilters = combine(
  {
    title: titleFilterState.$value,
    is_favorite: isFavoriteFilterState.$value,
    role: rolesFilterState.$value,
  },
  ({ title, is_favorite, role }) => {
    let filters: Opts = {}

    if (typeof title === 'string' && title.length > 0) {
      filters = Object.assign(filters, { title })
    }

    if (typeof is_favorite === 'boolean') {
      filters = Object.assign(filters, { is_favorite })
    }

    if (Array.isArray(role) && role.length > 0) {
      filters = Object.assign(filters, { role })
    }

    return filters
  },
)

export const $hasFilters = $channelFilters.map(Boolean)

export const filtersApplied = createEvent()

export const filtersReset = createEvent()

sample({
  clock: filtersReset,
  target: [
    titleFilterState.$value.reinit,
    isFavoriteFilterState.$value.reinit,
    rolesFilterState.$value.reinit,
  ] as const,
})

export const channelFiltersPopoverDisclosure = createDisclosureAtomImpl()

sample({
  clock: filtersApplied,
  source: $channelFilters,
  fn: filters =>
    ({
      params: {
        query: {
          title: filters?.title,
          is_favorite: filters?.is_favorite,
          role: filters?.role,
        },
      },
    }) satisfies EventPayload<typeof currentUserChannelsQuery.start>,
  target: currentUserChannelsQuery.start,
})

sample({
  clock: filtersReset,
  fn: () => ({}) satisfies EventPayload<typeof currentUserChannelsQuery.start>,
  target: currentUserChannelsQuery.start,
})

export type FilterType = keyof Opts

export const sidebarSelectedFilter = createStateOld<FilterType | null>({
  initial: null,
})
