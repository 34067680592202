import { format } from 'date-fns'

export function formatDateWithOffset(date: Date): string {
  const template = "yyyy-MM-dd'T'HH:mm:ssXXX"
  return format(date, template)
}

export function datesHasSameYear(dateOrDateStrings: (string | Date)[]) {
  return dateOrDateStrings
    .map(date => new Date(date).getFullYear())
    .every((year, _, years) => year === years[0])
}

export function datesHasSameDay(dateOrDateStrings: (string | Date)[]) {
  return dateOrDateStrings
    .map(date => new Date(date).getDate())
    .every((day, _, days) => day === days[0])
}

function isBasicTimeRange(times: string[]) {
  return times.length >= 2
    ? times[0] === '00:00:00' && times[times.length - 1] === '23:59:59'
    : true
}

export function datesHasSameTime(
  dateOrDateStrings: (string | Date)[],
): boolean {
  const times = dateOrDateStrings.map(date =>
    new Date(date).toLocaleTimeString('ru-RU'),
  )

  if (!isBasicTimeRange(times)) return false

  return times.slice(1, -1).every(time => time === times[0])
}

export function prepareTooltipDataRangeLabel(opts: {
  startDate: Date | string
  endDate: Date | string
  showYear: boolean
  showDay: boolean
  showTime: boolean
}) {
  const { startDate, endDate, showYear, showDay, showTime } = opts

  const dateFormat = showYear ? 'dd.MM.yyyy' : showDay ? 'dd.MM' : ''

  const timeFormat = showTime ? 'HH:mm' : ''

  const start = [dateFormat, timeFormat]
    .filter(Boolean)
    .map(formatString => format(new Date(startDate), formatString))
    .join(' ')

  const end = [dateFormat, timeFormat]
    .filter(Boolean)
    .map(formatString => format(new Date(endDate), formatString))
    .join(' ')

  return `${start} — ${end}`
}

export function formatAxisDate(opts: {
  date: Date | string
  showYear: boolean
  showDay: boolean
  showTime: boolean
}) {
  const { date, showYear, showDay, showTime } = opts

  const dateFormat = showYear ? 'dd.MM.yyyy' : showDay ? 'dd.MM' : ''

  const timeFormat = showTime ? 'HH:mm' : ''

  return [dateFormat, timeFormat]
    .filter(Boolean)
    .map(formatString => format(new Date(date), formatString))
    .join(' ')
}
