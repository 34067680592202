import { attach, createEvent, createStore, sample } from 'effector'
import { createAction } from 'effector-action'
import { env } from '~/env.mjs'

const $userId = createStore<number | null>(null)
export const setAnalyticsUserId = createAction({
  target: { $userId },
  fn: (target, userId: number | null) => {
    target.$userId(userId)
  },
})

const $channelId = createStore<number | string | null>(null)
export const setAnalyticsChannelId = createAction({
  target: { $channelId },
  fn: (target, channelId: number | string | null) => {
    target.$channelId(channelId)
  },
})

export type AnalyticsEventPayload = {
  event: string
} & Record<string, unknown>

function sendYMEvent({ event, ...params }: AnalyticsEventPayload) {
  if (typeof window !== 'undefined') {
    if (typeof ym === 'function') {
      ym(env.NEXT_PUBLIC_YANDEX_METRIKA_KEY, 'reachGoal', event, params)
    } else {
      console.warn('Яндекс метрика не установлена')
    }
  }
}

function sendGAEvent({ event, ...params }: AnalyticsEventPayload) {
  if (typeof window !== 'undefined') {
    if (typeof window.gtag === 'function') {
      window.gtag('event', event, params)
    } else {
      console.warn('Гугл аналитика не установлена')
    }
  }
}

export const sendAnalyticsEventsFx = attach({
  source: { user_id: $userId, channel_id: $channelId },
  effect: (init, events: AnalyticsEventPayload[]) => {
    events.forEach(({ event, ...params }) => {
      const payload = { event, ...init, ...params }
      sendGAEvent(payload)
      sendYMEvent(payload)
    })
  },
})

export const sendAnalyticsEvents = createEvent<AnalyticsEventPayload[]>()

sample({
  clock: sendAnalyticsEvents,
  filter: () => env.NEXT_PUBLIC_PRODUCTION == 1,
  target: sendAnalyticsEventsFx,
})
