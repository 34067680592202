import { invoke } from '@withease/factories'
import { createEffect, createEvent, sample } from 'effector'
import { createGate } from 'effector-react'
import { createDisclosureAtom } from '~/shared/lib/factories/create-disclosure'
import { $userRegisteredAt } from '~/user/model'

const KEY = 'release_modal_sales_2'
const RELEASE_DATE = new Date(2024, 8, 13)

export const releaseDisclosureAtom = invoke(() => createDisclosureAtom())
export const releaseNextButtonClicked = createEvent()

const checkLsFx = createEffect(() => {
  return !!window.localStorage.getItem(KEY)
})

const setLsFx = createEffect(() => {
  window.localStorage.setItem(KEY, '1')
})

export const ModalGate = createGate()

sample({
  clock: [ModalGate.open, $userRegisteredAt],
  source: { userRegisteredAt: $userRegisteredAt },
  filter: ({ userRegisteredAt }) =>
    !!userRegisteredAt &&
    new Date(userRegisteredAt).getTime() < RELEASE_DATE.getTime(),
  target: checkLsFx,
})

sample({
  clock: checkLsFx.doneData,
  filter: isDone => !isDone,
  target: releaseDisclosureAtom.open,
})

sample({ clock: releaseDisclosureAtom.closed, target: setLsFx })
