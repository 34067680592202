import { createApi, createStore, sample } from 'effector'
import { reverse, sort } from 'remeda'

export type AscendingOrder = 'asc' | 'desc'

type InitialState = {
  active?: boolean
  disabled?: boolean
  ascendingOrder?: AscendingOrder
}

export function createSorter<T>({
  fn,
  initialState = {},
}: {
  fn: (a: T, b: T) => number
  initialState?: InitialState
}) {
  const {
    active = false,
    disabled = false,
    ascendingOrder = 'asc',
  } = initialState

  const $sortingActive = createStore(active)

  const {
    sortingActivated,
    sortingDeactivated,
    sortingActiveToggled,
    sortingActiveChanged,
  } = createApi($sortingActive, {
    sortingActivated: () => true,
    sortingDeactivated: () => false,
    sortingActiveToggled: is => !is,
    sortingActiveChanged: (_, is: boolean) => is,
  })

  const $sortingDisabled = createStore(disabled)

  const {
    sortingDisabled,
    sortingEnabled,
    sortingDisabledToggled,
    sortingDisabledChanged,
  } = createApi($sortingDisabled, {
    sortingEnabled: () => false,
    sortingDisabled: () => true,
    sortingDisabledToggled: is => !is,
    sortingDisabledChanged: (_, is: boolean) => is,
  })

  const $sortingAscendingOrder = createStore<AscendingOrder>(ascendingOrder)

  const { sortingAscendingOrderToggled, sortingAscendingOrderChanged } =
    createApi($sortingAscendingOrder, {
      sortingAscendingOrderChanged: (_, ao: AscendingOrder) => ao,
      sortingAscendingOrderToggled: ao => (ao === 'asc' ? 'desc' : 'asc'),
    })

  sample({
    clock: $sortingDisabled,
    filter: Boolean,
    target: sortingDeactivated,
  })

  function applySorter(
    isActive: boolean,
    items: T[],
    ascending: AscendingOrder = 'asc',
  ) {
    if (!isActive) {
      return items
    }

    const sorted = sort(items, fn)

    return ascending === 'asc' ? sorted : reverse(sorted)
  }

  const unitShape = {
    sortingActive: $sortingActive,
    activateSorting: sortingActivated,
    deactivateSorting: sortingDeactivated,
    toggleSortingActive: sortingActiveToggled,
    changeSortingActive: sortingActiveChanged,
    sortingDisabled: $sortingDisabled,
    enableSorting: sortingEnabled,
    disableSorting: sortingDisabled,
    toggleSortingDisabled: sortingDisabledToggled,
    changeSortingDisabled: sortingDisabledChanged,
    ascendingOrder: $sortingAscendingOrder,
    toggleSortingAscending: sortingAscendingOrderToggled,
    changeSortingAscending: sortingAscendingOrderChanged,
  }

  const unitShapeProtocol = () => unitShape

  return {
    '@@unitShape': unitShapeProtocol,
    $sortingActive,
    sortingActivated,
    sortingDeactivated,
    sortingActiveToggled,
    sortingActiveChanged,
    $sortingDisabled,
    sortingEnabled,
    sortingDisabled,
    sortingDisabledToggled,
    sortingDisabledChanged,
    $sortingAscendingOrder,
    sortingAscendingOrderToggled,
    sortingAscendingOrderChanged,
    apply: applySorter,
  }
}
