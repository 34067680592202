import type { components } from '~/api/manage.generated'
import { createDisclosureAtomImpl } from '~/shared/lib/factories/create-disclosure'
import { createSorter } from '~/shared/lib/factories/create-sorter'

type ChannelSchema = components['schemas']['ChannelSchema']

export const channelsTitleSorter = createSorter<ChannelSchema>({
  fn: (a, b) =>
    a.channel.title
      .toLocaleLowerCase()
      .localeCompare(b.channel.title.toLocaleLowerCase(), 'ru'),
  initialState: {
    active: true,
    ascendingOrder: 'asc',
  },
})

export const channelsFavoriteSorter = createSorter<ChannelSchema>({
  fn: (a, b) => {
    if (a.is_favorite && !b.is_favorite) {
      return -1
    } else if (!a.is_favorite && b.is_favorite) {
      return 1
    } else {
      return 0
    }
  },
  initialState: {
    active: true,
  },
})

export const channelSortersPopoverDisclosure = createDisclosureAtomImpl()
